import React, {useEffect, useState} from 'react';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import HeroCard from './HeroCard';
import SellNftsModal from './SellNftsModal';
import HeroCardModal from '../collections/components/CardModal/HeroCardModal';
import styles from './styles/styles.css'
import Mint from './Mint';
import {getListings} from '../../api/near';
import INft from '../../interfaces/nfts';
import NoNft from "./NoNft";
import Filter from "../collections/components/Filter/Filter";

const Marketplace = () => {
    const [isActive, setIsActive] = useState<boolean>(false)
    const [mintedAmount, setMintedAmount] = useState<number>(0)
    const [items, setItems] = useState<INft[]>([])

    const getItems = async () => {
        const res = await getListings()
        console.log(res)
        if (res) setItems(res)
    }

    useEffect(() => {
        getItems()
        console.log(items)
    }, [])

    return (
        <>
            <div className={styles.page}>
                <Header/>
                <Mint/>
                <section className={styles.marketplaceSection}>
                    <div className={styles.container}>
                        <div className={styles.marketplaceHeader}>
                            <div className={styles.sellButton} onClick={() => setIsActive(true)}>
                                <span>Sell your NFT</span>
                            </div>
                            <div className={styles.sellButtonAdaptive} onClick={() => setIsActive(true)}>
                                <span>Sell</span>
                            </div>
                            <div className={styles.marketplaceTitleContainer}>
                                <h2 className={styles.marketplaceTitle}>marketplace</h2>
                                <span className={styles.marketplaceTitleItems}>{items.length} items</span>
                            </div>
                            <Filter/>

                        </div>

                        {items.length > 0 ? <div className={styles.cardsContainer}>
                            {
                                items.map((card, index) => (
                                    <HeroCard
                                        card={card}
                                        key={index}
                                    />
                                ))
                            }
                        </div> :
                            <NoNft/>
                        }
                    </div>

                </section>
                <Footer/>


            </div>
            <HeroCardModal filterNft={items}/>
            <SellNftsModal
                isActive={isActive}
                setIsActive={setIsActive}
            />


        </>
    );
};

export default Marketplace;