import React, { useState } from 'react';

import styles from './styles/filter.css'

import checkBox from '../../../../img/check_box.svg'

interface IProp {
    icon: string,
    content: string
}

const CheckboxItem = ({
    icon,
    content,
}: IProp) => {
    const [isChecked, setIsChecked] = useState(false)

    return (
        <div
            className={styles.filterItem}
            onClick={() => {
                setIsChecked(!isChecked)
            }}
        >
            <input type="checkbox" id="common" checked={isChecked} />
            <label onClick={()=>console.log(123)}>
                <div className={styles.checkmark}>
                    <img src={checkBox} className={styles.checkedIcon} alt='' />
                </div>
                <img src={icon} className={styles.rarityIcon} alt='' />
                {content}
            </label>
        </div>
    );
};

export default CheckboxItem;