import allRoutes from "./allRoutes.json"
import Collection from "../../../screens/collections/Сollections"
import Marketplace from "../../../screens/marketplace/Marketplace"
import Play from "../../../screens/play/Play"


const navigationMap = {
    [allRoutes.collections]: <Collection />,
    [allRoutes.marketplace]: <Marketplace />,
    [allRoutes.play]: <Play />
};

export default navigationMap;