import React from 'react';

import telegram from './img/link_telegram.svg'
import twitter from './img/link_twitter.svg'

import styles from './styles/footer.css'

const Footer = () => {
    return (
        <footer>
            <div className={styles.container}>
                <div className={styles.footerRow}>
                    <div className={styles.allRight}>
                        <p>© All Rights Reserved. Clash Arena</p>
                    </div>
                    <div className={styles.socials}>
                        <ul>
                            <li><a href="https://t.me/clasharenaio" target="_blank"><img src={telegram} alt='' /></a></li>
                            <li><a href="https://twitter.com/clasharenaio" target="_blank"><img src={twitter} alt='' /></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;