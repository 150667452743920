import React, { useContext } from 'react';
import { SliderContext } from './HeroCardSlider';
import classNames from 'classnames';

import styles from './styles/slider.css'

const Arrows = () => {
    const { changeSlide } = useContext(SliderContext)

    return (
        <div className={styles.arrows}>
            <div className={classNames(styles.arrow, styles.left)} onClick={() => changeSlide(-1)} />
            <div className={classNames(styles.arrow, styles.right)} onClick={() => changeSlide(1)} />
        </div>
    );
};

export default Arrows;