// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IC5c8 {\r\n    position: fixed;\r\n    top: 0;\r\n    bottom: 0;\r\n    left: 0;\r\n    right: 0;\r\n    z-index: 1000;\r\n    background-color: rgb(255, 255, 255, 0.1);\r\n\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n\r\n}\r\n\r\n.Qqhzt {\r\n    animation: WiY_D .7s ease forwards;\r\n    background-color: transparent;\r\n}\r\n\r\n@keyframes WiY_D {\r\n    0% {\r\n        transform: translateY(-100px);\r\n        opacity: 0;\r\n    }\r\n\r\n    50% {\r\n        opacity: 1;\r\n    }\r\n\r\n    100% {\r\n        transform: translateY(0);\r\n    }\r\n}", "",{"version":3,"sources":["webpack://./src/screens/collections/components/CardModal/styles/heroCardModal.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,SAAS;IACT,OAAO;IACP,QAAQ;IACR,aAAa;IACb,yCAAyC;;IAEzC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;AAEvB;;AAEA;IACI,kCAAsC;IACtC,6BAA6B;AACjC;;AAEA;IACI;QACI,6BAA6B;QAC7B,UAAU;IACd;;IAEA;QACI,UAAU;IACd;;IAEA;QACI,wBAAwB;IAC5B;AACJ","sourcesContent":[".modal-overlay {\r\n    position: fixed;\r\n    top: 0;\r\n    bottom: 0;\r\n    left: 0;\r\n    right: 0;\r\n    z-index: 1000;\r\n    background-color: rgb(255, 255, 255, 0.1);\r\n\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n\r\n}\r\n\r\n.modal {\r\n    animation: appearing .7s ease forwards;\r\n    background-color: transparent;\r\n}\r\n\r\n@keyframes appearing {\r\n    0% {\r\n        transform: translateY(-100px);\r\n        opacity: 0;\r\n    }\r\n\r\n    50% {\r\n        opacity: 1;\r\n    }\r\n\r\n    100% {\r\n        transform: translateY(0);\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalOverlay": "IC5c8",
	"modal": "Qqhzt",
	"appearing": "WiY_D"
};
export default ___CSS_LOADER_EXPORT___;
