import React, { useContext } from 'react';
import { SliderContext } from './HeroCardSlider'

import Slide from './Slide';

import styles from './styles/slider.css'

const SlidesList = () => {
    const { slideNumber, items } = useContext(SliderContext)

    return (
        <div
            className={styles.slideList}
            style={{ transform: `translateX(-${slideNumber * 100}%)` }}
        >
            {
                items.map((slide, index) =>
                (
                    <Slide
                        nft={slide}
                        key={index}
                    />
                ))
            }
        </div>
    );
};

export default SlidesList;