import React, {useRef} from 'react'
import classNames from 'classnames';

import closeIcon from '../../img/close.svg'
import networkNear from './img/svg/network_near.svg'
import walletNear from './img/svg/wallet_near.svg'
import check from './img/svg/check_circle.svg'
import styles from './styles/header.css'
import {requestSignIn} from "../../api/near";

interface IProp {
    isActive: boolean,
    setIsActive: (value: React.SetStateAction<boolean>) => void
}

const WalletModal = ({
    isActive,
    setIsActive
}: IProp) => {
    const takeProtocol = useRef<HTMLImageElement | null>(null);
    const changeOpacity = () => {
       if (takeProtocol.current){
           takeProtocol.current?.classList.add(styles.checked);
       }
    }
    return (
        <>
            {
                isActive
                && (
                    <div className={styles.modalOverlay}>
                        <div className={classNames(styles.modal, styles.walletModal)}>
                            <div className={styles.modalHeader}>
                                <div></div>
                                <div className={styles.modalHeaderTitle}>
                                    CHOOSE NETWORK AND WALLET
                                </div>
                                <div className={styles.close}>
                                    <img
                                        src={closeIcon}
                                        alt=''
                                        onClick={() => {
                                            setIsActive(false)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={styles.modalContent}>
                                <div className={styles.step}>
                                    <div className={styles.num}>
                                        1
                                    </div>
                                    <div className={styles.chose}>
                                        <div>
                                            <div ref={takeProtocol}  onClick={changeOpacity} className={styles.imgProtocol}>
                                                <img src={networkNear} alt='' />
                                            </div>
                                            <p>Near Protocol</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.step}>
                                    <div className={styles.num}>
                                        2
                                    </div>
                                    <div onClick={requestSignIn} className={styles.chose}>
                                        <div>
                                            <div className={styles.img}>
                                                <img src={walletNear} alt='' />
                                            </div>
                                            <p>Near Wallet</p>
                                        </div>
                                        <div className={styles.disabled}>
                                            <div className={styles.img}>
                                                <img src={walletNear} alt='' />
                                            </div>
                                            <p>Sender</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.modalFooter}>
                                    By connecting a wallet, you accept <a href="">Terms of Service</a> and <a href="">Privacy Policy</a>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    );
};

export default WalletModal