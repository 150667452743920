import { useEffect, useState } from "react"
import { getPlayerNfts } from "../../../api/near"
import INft from "../../../interfaces/nfts"

const useCollections = () => {
    const [nfts, setNfts] = useState<INft[]>([])

    const getNfts = async () => {
        try {
            const nftsRes = await getPlayerNfts()
            console.log(nftsRes?.length)
            if (!nftsRes || nftsRes.length === 0) return

            setNfts(nftsRes)
        } catch (error: any) { }
    }

    return {
        nfts,
        getNfts
    }
}

export default useCollections