import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom'
import classNames from 'classnames';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import HeroCardModal from './components/CardModal/HeroCardModal';
import HeroCard from './components/HeroCard/HeroCard';
import styles from './styles/collections.css'
import plusIcon from '../../img/plus_ico.svg'
import allRoutes from '../../configs/routers/appNavigation/allRoutes.json'
import useCollections from './hooks/useCollections';
import Statistics from "./components/Statistics";
import {getListings, getSaleInfo} from "../../api/near";
import Filter from "./components/Filter/Filter";
import INft from "../../interfaces/nfts";

const Сollections = () => {
    const {
        nfts,
        getNfts
    } = useCollections()

    const [items, setItems] = useState<INft[]>([])
    const getItems = async () => {
        const res = await getListings()
        console.log(res)
        if (res) setItems(res)
    }

    useEffect(() => {
        getNfts();
        getItems();
    }, [])
    //const get = getSaleInfo();
    //console.log(get)
    console.log(nfts)
    console.log(items)
    const filteredNfts = nfts.filter((item1) => {
        return !items.some((item2) => item2.name === item1.name);
    });

    console.log(filteredNfts);

    return (
        <>

            <div className={styles.page}>
                <Header />
                 <Statistics />
                <section className={styles.collectionsGallery}>
                    <div className={styles.container}>
                        <div className={styles.headNft}>
                            <div></div>
                            <h2>MY NFTs</h2>
                            <Filter/>
                        </div>

                        <div className={classNames(styles.row)}>

                            <div className={styles.heroCol}>
                                <Link to={allRoutes.marketplace}>
                                    <div className={styles.buyNew}>
                                        <div className={styles.plusWrapper}>
                                            <img src={plusIcon} alt="" />
                                        </div>
                                        <h3 className={styles.buyNewTitle}>CLICK TO BUY NFT</h3>
                                    </div>
                                </Link>
                            </div>

                            {
                                filteredNfts.map(card => (
                                    <HeroCard
                                        card={card}
                                    />
                                ))
                            }

                        </div>

                    </div>
                </section>
                <Footer />
            </div>
            <HeroCardModal filterNft={filteredNfts}/>
        </>
    );
};

export default Сollections;