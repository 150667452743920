import React from 'react'
import { getClassPic, getRarityPic, getCardClassName } from './helper/helper'
import { useAppDispatch } from '../../../../redux/hooks'
import { dispatchHeroModalIsActive, dispatchSelectedHeroId } from '../../../../redux/slices/heroModal'
import INft from '../../../../interfaces/nfts'
import styles from '../../styles/collections.css'

interface IProp {
    card: INft,
}

const HeroCard = ({
    card,
}: IProp) => {
    const dispatch = useAppDispatch()

    return (
        <div
            className={styles.heroCol}
            onClick={
                () => {
                    dispatch(dispatchHeroModalIsActive(true))
                    dispatch(dispatchSelectedHeroId(card.name))
                }}
        >
            <div className={getCardClassName(card.attributes[0].value, card.attributes[5].value, true)}>
                <div className={styles.heroLink}></div>
                <div className={styles.cardTop}>
                    {/*<div className={styles.crown}>*/}
                    {/*    /!*<img src={victoryStatIcon} alt='' />*!/*/}
                    {/*    <p>42%</p>*/}
                    {/*</div>*/}
                    <img src={card.image} alt='' className={styles.heroImg} />
                    <p className={styles.nomination}>{card.name}</p>
                </div>
                <div className={styles.cardBot}>
                    <div className={styles.rarityIcon}>
                        <div className={styles.iconWr}>
                            <div className={styles.iconIn}>
                                <img src={getRarityPic(card.attributes[5].value)} alt='' />
                            </div>
                        </div>
                    </div>
                    <div className={styles.rarity}>{card.attributes[5].value}</div>
                    <div className={styles.className}>{card.attributes[0].value}</div>
                    <div className={styles.classIcon}>
                        <div className={styles.iconWr}>
                            <div className={styles.iconIn}>
                                <img src={getClassPic(card.attributes[0].value)} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default HeroCard;