import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import WalletModal from './WalletModal';

import allRoutes from '../../configs/routers/appNavigation/allRoutes.json';

import logo from './img/svg/logo.svg';
import signIn from './img/svg/list_wallet.svg';
import logout from './img/svg/list_logout_black.svg';
import styles from './styles/header.css';
import { isSignIn, logOut, requestSignIn } from '../../api/near';

const Header = () => {
  const location = useLocation();

  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [isActive, setIsActive] = useState<boolean>(false);

  useEffect(() => {
    if (isActive) {
      document.body.style.overflowY = 'hidden';
      document.body.style.marginRight = '17px';
    } else {
      document.body.style.overflowY = 'auto';
      document.body.style.marginRight = '0';
    }
  }, [isActive]);

  useEffect(() => {
    setIsWalletConnected(isSignIn());
  }, []);

  return (
    <>
      <header>
        <div className={styles.container}>
          <div className={styles.menuRow}>
            <div className={styles.logo}>
              <Link to={allRoutes.play}>
                <img
                  src={logo}
                  alt="logo"
                />
              </Link>
            </div>
            <div className={styles.menu}>
              <ul>
                <li>
                  <span
                    onClick={() => {
                      window.location.replace(
                        'https://dev.clasharena.io/about'
                      );
                    }}
                  >
                    about
                  </span>
                </li>
                <li
                  className={
                    location.pathname === allRoutes.collections
                      ? styles.active
                      : undefined
                  }
                >
                  <span
                    onClick={() => {
                      window.location.replace(allRoutes.collections);
                    }}
                  >
                    collections
                  </span>
                </li>
                <li
                  className={
                    location.pathname === allRoutes.play
                      ? classNames(styles.play, styles.active)
                      : styles.play
                  }
                >
                  <span
                    onClick={() => {
                      window.location.replace(allRoutes.play);
                    }}
                  >
                    play
                  </span>
                </li>
                <li
                  className={
                    location.pathname === allRoutes.marketplace
                      ? styles.active
                      : undefined
                  }
                >
                  <span
                    onClick={() => {
                      window.location.replace(allRoutes.marketplace);
                    }}
                  >
                    marketplace
                  </span>
                </li>
                <li>
                  <span
                    onClick={() => {
                      window.location.replace(
                        'https://clasharenagame.gitbook.io/lightpaper/'
                      );
                    }}
                  >
                    docs
                  </span>
                </li>
              </ul>
            </div>
            {isWalletConnected ? (
              <div
                className={styles.connectAdaptive}
                onClick={() => {
                  logOut();
                  setIsWalletConnected(isSignIn());
                }}
              >
                <img
                  src={logout}
                  className={styles.iconWallet}
                />
              </div>
            ) : (
              <div
                className={styles.connectAdaptive}
                onClick={() => {
                  setIsActive(true);
                  //requestSignIn()
                }}
              >
                <img
                  src={signIn}
                  className={styles.iconWallet}
                />
              </div>
            )}

            {isWalletConnected ? (
              <div
                className={styles.connect}
                onClick={() => {
                  logOut();
                  setIsWalletConnected(isSignIn());
                }}
              >
                <p className={styles.bt}>Log out</p>
              </div>
            ) : (
              <div
                className={styles.connect}
                onClick={() => {
                  setIsActive(true);
                  //requestSignIn()
                }}
              >
                <p className={styles.bt}>Connect Wallet</p>
              </div>
            )}
          </div>
        </div>
      </header>
      <WalletModal
        isActive={isActive}
        setIsActive={setIsActive}
      />
    </>
  );
};

export default Header;
