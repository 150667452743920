import classNames from 'classnames'

import commonIcon from '../../../../../img/rarity_common.svg'
import rareIcon from '../../../../../img/rarity_rare.svg'
import legendaryIcon from '../../../../../img/rarity_legendary.svg'
import assassinIcon from '../../../../../img/class_assassin.svg'
import fighterIcon from '../../../../../img/class_fighter.svg'
import rangerIcon from '../../../../../img/class_ranger.svg'

import Rarity from '../../HeroCard/enum/rarity'
import Classes from '../../HeroCard/enum/classes'

import styles from '../styles/slider.css'

export const getRarityPic = (rarity: string) => {
    if (rarity === Rarity.common) return commonIcon
    if (rarity === Rarity.rare) return rareIcon
    if (rarity === Rarity.legendary) return legendaryIcon

    return undefined
}

export const getClassPic = (classType: string) => {
    if (classType === Classes.assasin) return assassinIcon
    if (classType === Classes.warrior) return fighterIcon
    if (classType === Classes.mage) return rangerIcon

    return undefined
}

export const getAttributePic = (value: string) => {
    if (value === Rarity.common) return commonIcon
    if (value === Rarity.rare) return rareIcon
    if (value === Rarity.legendary) return legendaryIcon
    if (value === Classes.assasin) return assassinIcon
    if (value === Classes.warrior) return fighterIcon
    if (value === Classes.mage) return rangerIcon

    return undefined
}

export const getCardClassName = (
    classType: string,
    rarity: string,
) => {
    const result = {
        rarityStyles: '',
        classTypeStyles: ''
    }

    if (classType === Classes.assasin) result.classTypeStyles = styles.assasin
    if (classType === Classes.warrior) result.classTypeStyles = styles.fighter
    if (classType === Classes.mage) result.classTypeStyles = styles.ranger

    if (rarity === Rarity.common) result.rarityStyles = styles.common
    if (rarity === Rarity.rare) result.rarityStyles = styles.rare
    if (rarity === Rarity.legendary) result.rarityStyles = styles.legendary


    return classNames(styles.heroContent, result.rarityStyles, result.classTypeStyles)
}

export const getStats = (classType: string) => {
    switch (classType) {
        case Classes.assasin: return {
            health: '85',
            energy: '10',
            attack: '11',
            defence: '13',
        }
        case Classes.mage: return {
            health: '95',
            energy: '10',
            attack: '25',
            defence: '11',
        }
        case Classes.warrior: return {
            health: '100',
            energy: '12',
            attack: '16',
            defence: '18',
        }
    }
}
