import { log } from 'console';
import { keyStores, connect, WalletConnection, Contract, utils } from 'near-api-js'
const battleContractAddress = "battle.battler2.testnet";
const nftContractAddress = "nft.battler2.testnet";
const marketplaceContractAddress = 'marketplace.battler2.testnet'
const saleContractAddress = 'sale.battler2.testnet'

const nearConfig = {
    networkId: "testnet",
    nodeUrl: "https://rpc.testnet.near.org",
    // contractAddress: battleContractAddress,
    walletUrl: "https://wallet.testnet.near.org",
    helperUrl: "https://helper.testnet.near.org",
    keyStore: new keyStores.BrowserLocalStorageKeyStore(),
};

let near;
let walletConnection: WalletConnection;

let battleContract: any
let marketplaceContract: any
let nftContract: any
let saleContract: any
let lastBattleId: any

let currentBattleId;
let actions: any[] = [];

export const logOut = () => walletConnection.signOut()

export const requestSignIn = async () => await walletConnection.requestSignIn({ contractId: battleContractAddress });

export const isSignIn = () => walletConnection.isSignedIn()

const connectToNear = async () => {
    near = await connect(nearConfig);

    walletConnection = new WalletConnection(near, 'battler');

    battleContract = new Contract(walletConnection.account(), battleContractAddress, {
        viewMethods: [
            "get_battle_info",
            "get_stats",
            "get_my_battles",
            "get_opened_battles",
            "get_player_for_battle",
            "get_my_last_battle",
        ],
        changeMethods: [
            "create_battle",
            "use_boost",
            "send_actions",
            "approve_hash",
            "join_battle",
            "remove_last_battle",
        ],
    });

    nftContract = new Contract(walletConnection.account(), nftContractAddress, {
        viewMethods: [
            "get_tokens_of_user"
        ],
        changeMethods: [
            'nft_approve'
        ]
    });

    marketplaceContract = new Contract(walletConnection.account(), marketplaceContractAddress, {
        viewMethods: [
            'get_sales_by_nft_contract_id',
            'storage_balance_of',
            'storage_minimum_balance',
            'get_supply_by_owner_id'
        ],
        changeMethods: [
            'storage_deposit',
            'offer',
            'remove_sale'
        ]
    });

    saleContract = new Contract(walletConnection.account(), saleContractAddress, {
        viewMethods: [
            'get_sale_data'
        ],
        changeMethods: [
            'query_nft_mint'
        ]
    })
};

export const fromYocto = (amount: string) => utils.format.formatNearAmount(amount)
export const toYocto = (amount: string) => utils.format.parseNearAmount(amount)
export const getAccountId = () => walletConnection.getAccountId()

export async function UseBoost(boostId: any) {
    actions = [];

    const res = await battleContract.use_boost({ args: { "battle_id": lastBattleId, "boost_index": boostId }, gas: "50000000000000" });
}
export async function SendActions(acts: any) {
    for (let i = 0; i < acts.length; i++) {
        actions.push(acts[i]);
    }
    const res = await battleContract.send_actions({
        "data": acts,
        "battle_id": lastBattleId,
    });
};

export async function removeLastBatttle() {
    await battleContract.remove_last_battle();
}
export async function approveActions() {
    // console.log(actions);
    const res = await battleContract.approve_hash({ args: { "data": actions, "battle_id": lastBattleId }, gas: "300000000000000" });
}
const createBattle = async () => {
    // console.log(battleContract);
    const lastBattleId = await battleContract.create_battle({
        args: {},
        gas: "300000000000000",
        amount: "100000000000000000000000",
    });
    window.location.pathname = `${lastBattleId}`;
    // console.log(lastBattleId);
}

export const getMyLastBattle = async (sendCotleta: (info: any) => void) => {
    const accId = getAccountId()
    const res = await battleContract.get_my_last_battle({ acc: accId });

    lastBattleId = res;
    if (lastBattleId != null) {
        let info = await getBattleInfo(lastBattleId, sendCotleta);
        periodically(getBattleInfo, 2000, lastBattleId, sendCotleta);
        return info
    }
    return null;
};
const get_my_battles = async () => {
    const res = await battleContract.get_my_battles({});
    // console.log(res);
};

export const getOpenedBattles = async () => {
    const rest = await battleContract.get_opened_battles();
    // console.log(rest);
    return rest;
};

export const joinBattle = async (nftID: any) => {
    const res = await battleContract.get_opened_battles();
    if (res.length == 0) {
        currentBattleId = await battleContract.create_battle({
            args: { token_id: nftID },
            gas: "300000000000000",
            amount: "1000000000000000000000000"
        });
    }
    else {
        currentBattleId = await battleContract.join_battle({
            args: { token_id: nftID },
            gas: "300000000000000",
            amount: "1000000000000000000000000"
        });
    }
}

async function periodically(func: any, interval: any, ...args: any) {
    try {
        await func(...args);

        const timeout = setTimeout(
            () => {
                clearTimeout(timeout);
                periodically(func, interval, ...args);
            },
            interval,
        );
    } catch (err) {
        console.error('periodically error: ', err);
    }
}

const getBattleInfo = async (battle_id: string, sendCotleta: (info: any) => void) => {
    
    const res = await battleContract.get_battle_info({ battle_id: battle_id });
    
    // console.log(res)
    const strStart = res.search("\"bid\":") + String("\"bid\":").length;
    const strEnd = res.search(",\"first_player_boost\"");
    const bidStr = res.slice(strStart, strEnd);;
    const info = JSON.parse(res, function (k, v) {
        if (v == null) {
            v = "";
        }
        return v;
    });
    info.bid = bidStr;

    const unityInfo: any = {};
    unityInfo.Bid = bidStr;
    unityInfo.CurrentStage = info.current_stage;
    unityInfo.FirstPlayer = info.first_player;
    unityInfo.SecondPlayer = info.second_player;
    unityInfo.CurrentStepOwner = info.turn_owner;
    unityInfo.FirstPlayerMove = info.first_player_actions;
    unityInfo.SecondPlayerMove = info.second_player_actions;
    unityInfo.FirstPlayerStats = {
        maxHp: String(info.first_player_stats.max_hp),
        currentHp: String(info.first_player_stats.current_hp),
        maxEnergy: String(info.first_player_stats.max_energy),
        energy: String(info.first_player_stats.energy),
        attack: String(info.first_player_stats.attack),
        defence: String(info.first_player_stats.armor),
        healAmount: String(info.first_player_stats.heal_amount),
        energyHealAmount: String(info.first_player_stats.energy_heal_amount),
        playerTraits: {
            shield: info.first_player_stats.traits.shield.name,
            weapon: info.first_player_stats.traits.weapon.name,
            skin: info.first_player_stats.traits.skin.name,
            headwear: info.first_player_stats.traits.headwear.name,
            class: info.first_player_stats.traits.class.name,
        }
    }
    unityInfo.SecondPlayerStats = {
        maxHp: String(info.second_player_stats.max_hp),
        currentHp: String(info.second_player_stats.current_hp),
        maxEnergy: String(info.second_player_stats.max_energy),
        energy: String(info.second_player_stats.energy),
        attack: String(info.second_player_stats.attack),
        defence: String(info.second_player_stats.armor),
        healAmount: String(info.second_player_stats.heal_amount),
        energyHealAmount: String(info.second_player_stats.energy_heal_amount),
        playerTraits: {
            shield: info.second_player_stats.traits.shield.name,
            weapon: info.second_player_stats.traits.weapon.name,
            skin: info.second_player_stats.traits.skin.name,
            headwear: info.second_player_stats.traits.headwear.name,
            class: info.second_player_stats.traits.class.name,
        }
    }
    unityInfo.RoundCount = info.round_count;
    unityInfo.FirstPlayerRunes = info.first_player_runes;
    unityInfo.SecondPlayerRunes = info.second_player_runes;
    unityInfo.FirstPlayerAvailableRunes = info.first_player_available_boosts;
    unityInfo.SecondPlayerAvailableRunes = info.second_player_available_boosts;

    if (unityInfo.FirstPlayerMove.length !== unityInfo.SecondPlayerMove.length) {
        unityInfo.FirstPlayerMove = unityInfo.SecondPlayerMove;
    }
    if (unityInfo.SecondPlayerMove.length !== unityInfo.FirstPlayerMove.length) {
        unityInfo.SecondPlayerMove = unityInfo.FirstPlayerMove;
    }
    sendCotleta(unityInfo)
    return unityInfo;
};

export const getNftMetadata = async (id: string) => {
    const res = await fetch(`https://amber-certain-bedbug-987.mypinata.cloud/ipfs/QmRdge8TBx8c4DdLrwynGJN1Nficr6pUzZWtZeGevSkWvJ/${id}.json`)
        .then(res => res.json())

    return res
}

export const getPlayerNfts = async () => {
    const userId = walletConnection.getAccountId()
    console.log(userId)
    const res: string[] = await nftContract.get_tokens_of_user({ user: userId })
    if (res.length === 0) return

    const nfts = await Promise.all(res.map(elem => getNftMetadata(elem)))
    return nfts
}

export const getPlayersForUnity = async () => {
    const userId = getAccountId()
    const res = await nftContract.get_tokens_of_user({ user: userId })
    if (res.length === 0) return 'no'
    const nfts = await formatNFTsToUnityClass(res);
    return nfts
}

const formatNFTsToUnityClass = async (arr: any[]) => {
    let objects = [];
    for (let i = 0; i < arr.length; i++) {
        let res = await fetch(`https://amber-certain-bedbug-987.mypinata.cloud/ipfs/QmRdge8TBx8c4DdLrwynGJN1Nficr6pUzZWtZeGevSkWvJ/${arr[i]}.json`);
        let obj = await res.json();
        let char = {
            tokenId: arr[i],
            traitsOfTokenId: {
                shield: obj.attributes[3].value,
                weapon: obj.attributes[2].value,
                skin: obj.attributes[1].value,
                headwear: obj.attributes[4].value,
                ch_class: obj.attributes[0].value,
                rarity: obj.attributes[5].value
            }
        }
        objects.push(char);
    }
    // console.log(objects);
    return objects;
}

export const getSaleInfo = async () => await saleContract.get_sale_data({})

export const getListings = async () => {
    const res = await marketplaceContract.get_sales_by_nft_contract_id({
        nft_contract_id: nftContractAddress,
        from_index: '0',
        limit: 50
    })

    const result: any[] = []

    for (let index = 0; index < res.length; index++) {
        const nft = await getNftMetadata(res[index].token_id)
        result.push({
            ...nft,
            sale_conditions: res[index].sale_conditions,
            owner: res[index].owner_id
        })
    }

    return result
}

export const isEnoughDeposit = async () => {
    const balanceOf: any = await marketplaceContract.storage_balance_of({
        account_id: getAccountId(),
    })

    let minimumStorage: any = await marketplaceContract.storage_minimum_balance()
    let nftsCount = 1 + await marketplaceContract.get_supply_by_owner_id({
        account_id: getAccountId()
    });
    let dep  = BigInt(balanceOf) - (BigInt(minimumStorage) * BigInt(nftsCount));
    let isEnoughDep = dep >= BigInt(minimumStorage);
    if (dep < BigInt(minimumStorage)) {
        dep = BigInt(minimumStorage);
    }
    
  
    return {isEnough: isEnoughDep,
            depositAmount: dep}
}

export const Deposit = async () => {
    const depInfo = await isEnoughDeposit();
    await marketplaceContract.storage_deposit({
        args: {
            account_id: getAccountId(),
        },
        gas: "300000000000000",
        amount: depInfo.depositAmount.toString(),
    })
}

export const listNft = async (tokenId: string, price: string) => {
    const balanceOf: any = await marketplaceContract.storage_balance_of({
        account_id: getAccountId(),
    })

    let minimumStorage: any = await marketplaceContract.storage_minimum_balance()
    let nftsCount = 1 + await marketplaceContract.get_supply_by_owner_id({
        account_id: getAccountId()
    });
    let dep  = BigInt(minimumStorage) * BigInt(nftsCount) - BigInt(balanceOf);

    // console.log( minimumStorage, balanceOf, nftsCount, dep.toString()); 
    // console.log(BigInt(minimumStorage), BigInt(nftsCount), BigInt(balanceOf));
    // console.log(nftsCount * minimumStorage)
    // console.log(BigInt(balanceOf) >= BigInt(nftsCount) * BigInt(minimumStorage));
    // console.log(BigInt(balanceOf), BigInt(nftsCount) * BigInt(minimumStorage));
    const enough = await isEnoughDeposit();
    if (true) {
        const res = await nftContract.nft_approve({
            args: {
                token_id: tokenId,
                account_id: marketplaceContractAddress,
                msg: JSON.stringify({
                    sale_conditions: toYocto(price)
                }),
            },
            gas: "300000000000000",
            amount: "4000000000000000000000",
        })

        // console.log(res)
        return res
    }
    await marketplaceContract.storage_deposit({
        args: {
            account_id: getAccountId(),
        },
        gas: "300000000000000",
        amount: dep.toString(),
    })
}

export const mint = async () => {
    const res = await saleContract.query_nft_mint({
        args: {
            mint_to: getAccountId(),
        },
        gas: "300000000000000",
        amount: "100000000000000000000000",
    })
    return res
}

export const buyNft = async (tokenId: string, price: string) => {
    const res = await marketplaceContract.offer({
        args: {
            nft_contract_id: nftContractAddress,
            token_id: tokenId
        },
        gas: "300000000000000",
        amount: price,
    })
    return res
}

export const delistNft = async (tokenId: string) => {
    await marketplaceContract.remove_sale({
        args: {
            nft_contract_id: nftContractAddress,
            token_id: tokenId
        },
        gas: "300000000000000",
        amount: "1",
    })
}

connectToNear()