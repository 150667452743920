import React, {useEffect, useRef, useState} from 'react';

import collectionsNfts from '../../configs/nfts/collectionsNfts';

import closeIcon from '../../img/close.svg'
import SellNftItem from './SellNftItem';

import styles from './styles/styles.css'
import useCollections from "../collections/hooks/useCollections";
import INft from "../../interfaces/nfts";
import {getListings, isEnoughDeposit} from "../../api/near";

interface IProp {
    setIsActive: (value: React.SetStateAction<boolean>) => void,
    isActive: boolean
}

const SellNftsModal = ({isActive, setIsActive}: IProp) => {
    const page: HTMLElement | null = document.querySelector(".CyFcQ");

    const {
        nfts,
        getNfts
    } = useCollections()
    const [items, setItems] = useState<INft[]>([])
    const getItems = async () => {
        const res = await getListings()
        console.log(res)
        if (res) setItems(res)
    }


    useEffect(() => {
        getNfts();
        getItems();
    }, [])
    const filteredNfts = nfts.filter((item1) => {
        return !items.some((item2) => item2.name === item1.name);
    });

    useEffect(() => {
        if (isActive) {
            if (page) page.style.filter = "blur(2px)";
            document.body.style.overflowY = 'hidden'
            document.body.style.marginRight = '17px'
        } else {
            if (page) page.style.filter = "blur(0px)";
            document.body.style.overflowY = 'auto'
            document.body.style.marginRight = '0'
        }
    }, [isActive])

    const modalBodyNftsWrapperRef = useRef<HTMLDivElement | null>(null);
    function handleWheel(event: React.WheelEvent<HTMLDivElement>){
        const element = modalBodyNftsWrapperRef.current;
        if(element){
            const delta = event.deltaY;
            element.scrollLeft += delta;
        }
    }

    return (
        <>
            {
                isActive
                && (
                    <div className={styles.modalOverlay}>
                        <div className={styles.modal}>
                            <div className={styles.modalHeader}>
                                <h1 className={styles.modalHeaderTitle}>YOUR NFTs</h1>
                                <img
                                    className={styles.modalHeaderClose}
                                    src={closeIcon}
                                    alt=""
                                    onClick={() => {
                                        setIsActive(false)
                                    }}
                                />
                            </div>
                            <div className={styles.modalBody}>
                                    <div
                                        ref={modalBodyNftsWrapperRef}
                                        onWheel={handleWheel}
                                        className={styles.modalBodyNftsWrapper}
                                    >
                                        {
                                            filteredNfts.map((nft, index) => (
                                                <SellNftItem
                                                    nft={nft}
                                                    key={index}
                                                />
                                            ))
                                        }

                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    );
};

export default SellNftsModal;