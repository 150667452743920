import React, {useEffect, useRef, useState} from 'react'
import classNames from 'classnames'
import {getCardClassName, getClassPic, getRarityPic, getStats, getAttributePic} from './helper/helper'
import {useAppDispatch} from '../../../../redux/hooks'
import {dispatchHeroModalIsActive} from '../../../../redux/slices/heroModal'

import HeroSendDropdown from './HeroSendDropdown'

import INft from '../../../../interfaces/nfts'

import close from '../../../../img/close.svg'
import statPrice from '../../../../img/stat_price.svg'
import statBattels from '../../../../img/stat_battles.svg'
import statVictory from '../../../../img/stat_victory.svg'
import statWinStreak from '../../../../img/stat_win_streak.svg'
import healthStat from '../../../../img/stats_health.svg'
import attackStat from '../../../../img/stats_attack.svg'
import defenceStat from '../../../../img/stats_defence.svg'
import energyStat from '../../../../img/stats_energy.svg'
import abilityIcon from '../../../../img/stats_survival.svg'
import routes from '../../../../configs/routers/appNavigation/allRoutes.json'
import styles from './styles/slider.css'
import {buyNft, delistNft, Deposit, fromYocto, getAccountId, isEnoughDeposit, listNft} from '../../../../api/near'
import {useLocation, useNavigate} from "react-router-dom";

interface IProp {
    nft: INft,
}

const Slide = ({nft}: IProp) => {
    const [amount, setAmount] = useState('')
    const dispatch = useAppDispatch()
    const stats = getStats(nft.attributes[0].value)
    const [enough, setEnough] = useState<boolean>();


    useEffect(() => {
         async function isEnough() {
            const info = await isEnoughDeposit();
            setEnough(info.isEnough)
        }
        isEnough();
    }, [enough])

    const location = useLocation();
    console.log(enough)
    const inputBack = () => {
        if (location.pathname === routes.marketplace) {
            return styles.priceWrapperBlack
        } else {
            return styles.priceWrapper
        }
    }

    return (
        <div className={styles.slide}>
            <div className={styles.header}>
                <span className={styles.nomination}>{nft.name}</span>
                <img className={styles.close} src={close} alt={close}
                     onClick={() => dispatch(dispatchHeroModalIsActive(false))}/>
            </div>
            <div className={styles.slideContent}>
                <div className={styles.rightStat}>
                    <p className={styles.title}>Stats</p>
                    <div className={styles.statItem}>
                        <div className={classNames(styles.item, styles.health)}>
                            <img src={healthStat} alt=''/>
                            <p>Health <span>{stats?.health}</span></p>
                        </div>
                        <div className={classNames(styles.item, styles.energy)}>
                            <img src={energyStat} alt=''/>
                            <p>Energy <span>{stats?.energy}</span></p>
                        </div>
                        <div className={classNames(styles.item, styles.attack)}>
                            <img src={attackStat} alt=''/>
                            <p>Attack <span>{stats?.attack}</span></p>
                        </div>
                        <div className={classNames(styles.item, styles.defence)}>
                            <img src={defenceStat} alt=''/>
                            <p>Defence <span>{stats?.defence}</span></p>
                        </div>
                    </div>
                    {/* <div className={styles.feature}>
                        <p className={styles.top}><img src={abilityIcon} alt='' />Ability</p>
                        <p className={styles.text}>{getStats(nft.attributes[0].value).feature}</p>
                    </div> */}
                </div>
                <div className={getCardClassName(nft.attributes[0].value, nft.attributes[5].value)}>
                    <HeroSendDropdown/>
                    {/* <div className={styles.leftStat}>
                        <div className={styles.statItem}>
                            <img src={statWinStreak} alt='' />
                            <p>5 <span>WINS</span></p>
                        </div>
                        <div className={styles.statItem}>
                            <img src={statVictory} alt='' />
                            <p>42%</p>
                        </div>
                        <div className={styles.statItem}>
                            <img src={statBattels} alt='' />
                            <p>34</p>
                        </div>
                    </div> */}
                    <img src={nft.image} alt='' className={styles.heroMainImg}/>
                    <div className={styles.wrapFooter}>
                        <div className={styles.heroContentBot}>
                            <div className={styles.rarityIcon}>
                                <div className={styles.iconWr}>
                                    <div className={styles.iconIn}>
                                        <img src={getRarityPic(nft.attributes[5].value)} alt=''/>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.rarity}>{nft.attributes[5].value}</div>
                            <div className={styles.classType}>{nft.attributes[0].value}</div>
                            <div className={styles.classIcon}>
                                <div className={styles.iconWr}>
                                    <div className={styles.iconIn}>
                                        <img src={getClassPic(nft.attributes[0].value)} alt=''/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {enough ? <div className={styles.buyContent}>
                                <div className={inputBack()}>
                                    <input
                                        placeholder="Price NEAR"
                                        type="number"
                                        value={nft.sale_conditions ? fromYocto(nft.sale_conditions) : amount}
                                        readOnly={Boolean(nft.sale_conditions)}
                                        onChange={e => setAmount(e.target.value)}
                                    />
                                </div>
                                <div
                                    className={
                                        nft.sale_conditions
                                            ? nft.owner === getAccountId()
                                                ? styles.cancelButton : styles.buyButton
                                            : styles.cancelButton
                                    }
                                    onClick={async () => {await listNft(`${nft.edition}`, amount)}}
                                >
                                    <span>Sell</span>
                                </div>
                            </div> :
                            <div className={styles.depositStorage} onClick={async () => {await Deposit()}}>
                                <span>DEPOSIT STORAGE</span>
                            </div>}
                    </div>

                </div>
                <div className={styles.attributesContainer}>
                    <h3 className={styles.attributesTitle}>Attributes</h3>
                    {
                        nft.attributes.map((attribute) => (
                            <div className={styles.traiteContainer}>
                                <span className={styles.traiteType}>{attribute.trait_type}</span>
                                <div style={{position: "relative"}}>
                                    {
                                        (getAttributePic(attribute.value) !== undefined)
                                        && (
                                            <img className={styles.traiteValueImg} src={getAttributePic(attribute.value)}
                                                 alt=""/>
                                        )
                                    }
                                    <span className={styles.traiteValue}>{attribute.value}</span>
                                </div>

                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

export default Slide;