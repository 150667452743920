import React, { useEffect } from 'react';
import { useAppSelector } from '../../../../redux/hooks';

import Slider from '../Slider/HeroCardSlider'

import styles from './styles/heroCardModal.css'
import INft from "../../../../interfaces/nfts";
import {useLocation} from "react-router-dom";
import routes from "../../../../configs/routers/appNavigation/allRoutes.json";

interface IHeroCardModal {
    filterNft: INft[]
}

const HeroCardModal: React.FC<IHeroCardModal> = ({filterNft} ) => {
    const { isActive } = useAppSelector(state => state.heroModal);
    const pageCollections:HTMLElement | null  = document.querySelector(".tMGlO");
    const pageMarketPlace:HTMLElement | null = document.querySelector(".CyFcQ")

    useEffect(() => {
        if (isActive) {
            if(pageMarketPlace) pageMarketPlace.style.filter = "blur(2px)"
            if(pageCollections) pageCollections.style.filter = "blur(2px)";
            document.body.style.overflowY = 'hidden'
            document.body.style.marginRight = '17px'
        }
        else {
            if(pageMarketPlace) pageMarketPlace.style.filter = "blur(0px)"
            if(pageCollections) pageCollections.style.filter = "blur(0px)";
            document.body.style.overflowY = 'auto'
            document.body.style.marginRight = '0'
        }
    }, [isActive])

    return (
        <>
            {
                isActive
                && (
                    <div
                        className={styles.modalOverlay}
                    >
                        <div className={styles.modal}>
                            <Slider filterNft={filterNft}/>
                        </div>
                    </div >
                )
            }
        </>
    );
};

export default HeroCardModal;