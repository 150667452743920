import React, { useEffect, useRef, useState } from 'react'

import moreVert from '../../../../img/more_vert.svg'
import listSend from '../../../../img/list_send.svg'
import listTrade from '../../../../img/list_trade.svg'
import allRoutes from '../../../../configs/routers/appNavigation/allRoutes.json'
import styles from './styles/slider.css'
import {useNavigate} from "react-router-dom";

const HeroSendDropdown = () => {
    const dropdownRef = useRef<HTMLDivElement>(null)
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const navigate = useNavigate();

    useEffect(() => {
        const handleClick = (e: MouseEvent) => {
            if (!isOpen) {
                return
            }
            if (e.target instanceof Element && dropdownRef.current?.contains(e.target)) {
                return
            }

            setIsOpen(false)
        }

        document.addEventListener('click', handleClick)

        return () => {
            document.removeEventListener('click', handleClick)
        }

    }, [isOpen])

    return (
        <div
            className={styles.heroSend}
            ref={dropdownRef}
            onClick={() => setIsOpen(!isOpen)}
        >
            <div className={styles.sendMore}>
                <img src={moreVert} alt='' />
            </div>
            {
                isOpen
                && (
                    <div className={styles.sendTrade}>
                        <div className={styles.item}><img src={listSend} alt='' />Send</div>
                        <div onClick={() => navigate(allRoutes.marketplace)}
                             className={styles.item}><img src={listTrade} alt='' />Go to trade</div>
                    </div>
                )
            }
        </div>
    );
};

export default HeroSendDropdown;