import React, { createContext, useEffect, useState } from 'react'
import { useAppSelector } from '../../../../redux/hooks'
import { useLocation } from 'react-router-dom'

import collectionsNfts from '../../../../configs/nfts/collectionsNfts'
import marketplaceNfts from '../../../../configs/nfts/marketplaceNfts'

import INft from '../../../../interfaces/nfts'
import Arrows from './Arrows'
import SlidesList from './SlidesList'

import allRoutes from '../../../../configs/routers/appNavigation/allRoutes.json'

import styles from './styles/slider.css'
import useCollections from '../../hooks/useCollections'
import { getListings } from '../../../../api/near'
import filter from "../Filter/Filter";

interface ISliderContext {
    changeSlide: (direction?: number) => void,
    goToSlide: (number: number) => void,
    items: INft[],
    slidesCount: number,
    slideNumber: number,
}

export const SliderContext = createContext<ISliderContext>({
    changeSlide: () => { },
    goToSlide: () => { },
    items: [],
    slideNumber: 0,
    slidesCount: 0
})

interface IHeroCardSlider {
    filterNft: INft[]
}

const HeroCardSlider:React.FC<IHeroCardSlider> = ({filterNft}) => {
    const [items, setItems] = useState<INft[]>([]);
    const [slide, setSlide] = useState(0);
    const [nftMarketPlace, setNftMarketPlace] = useState<INft[]>([]);
    const location = useLocation()

    const getNftMarketPlace = async () => {
        const nftsMarket = await getListings();
        setNftMarketPlace(nftsMarket);
        setItems(nftsMarket);
        const selectedSlide = items.findIndex(item => {
            return item.name === selectedHeroName;
        })
        console.log(selectedSlide)
        setSlide(selectedSlide)
        return nftsMarket;
    }
    const {
        selectedHeroName
    } = useAppSelector(state => state.heroModal)

    const {
        getNfts,
        nfts
    } = useCollections()

    const findSlide = () => {
        const selectedSlide = items.findIndex(item => {
            return item.name === selectedHeroName;
        })
        console.log(selectedSlide)
        setSlide(selectedSlide)
    }


    useEffect(() => {
        setItems(filterNft);
        console.log(items)
        findSlide();
    }, [nfts])

    useEffect(() => {
        setItems(nftMarketPlace)
        findSlide();
        console.log(nftMarketPlace)
    }, [nftMarketPlace])


    const setSelectedSlide = async () => {
        try {
            switch (location.pathname){
                case (allRoutes.collections):
                    await getNfts()
                    console.log(nfts)
                    break
                case (allRoutes.marketplace):
                    await getNftMarketPlace()
                    break
                default :
                    const listings = await getListings()
                    setItems(listings)
            }

            console.log(selectedHeroName)
            console.log(items)

        } catch (error: any) {
            console.log(error.message)
        }

        return 0
    }

    useEffect(() => {
        if (selectedHeroName !== undefined && items.length === 0) {
            setSelectedSlide()
        }
    }, [items])

    const changeSlide = (direction = 1) => {
        let slideNumber = 0;

        if (slide + direction < 0) {
            slideNumber = items.length - 1;
        } else {
            slideNumber = (slide + direction) % items.length;
        }

        setSlide(slideNumber);
    };

    const goToSlide = (number: number) => {
        setSlide(number % items.length);
    };


    return (
        <SliderContext.Provider
            value={{
                changeSlide,
                goToSlide,
                items,
                slidesCount: items.length,
                slideNumber: slide,
            }
            }
        >
            <div className={styles.slider}>
                <SlidesList />
            </div>
            <Arrows />
        </SliderContext.Provider>
    );
};

export default HeroCardSlider;