import React, { useEffect, useRef, useState } from 'react';

import sortOptions from './config/sortOptions';
import { changeCurrentSortBy } from './helper/helper'

import styles from './styles/filter.css'

const SortDropdown = () => {
    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef = useRef<HTMLDivElement>(null)

    const hideDropDownContent = (e: MouseEvent) => {
        if (!isOpen) {
            return
        }
        if (e.target instanceof Element && dropdownRef.current?.contains(e.target)) {
            return
        }

        setIsOpen(false)
    }

    useEffect(() => {
        document.addEventListener('click', hideDropDownContent)

        return () => {
            document.removeEventListener('click', hideDropDownContent)
        }
    }, [isOpen])

    return (
        <div className={styles.dropDown} ref={dropdownRef}>
            <div
                className={styles.selectItem}
                onClick={() => {
                    setIsOpen(!isOpen)
                }}
            >
                <span>{sortOptions[0]}</span>
            </div>
            {
                isOpen
                && (
                    <div className={styles.selectedItems}>
                        <ul>
                            {
                                sortOptions.map(option => (
                                    <li
                                        onClick={() => {
                                            changeCurrentSortBy(sortOptions, option)
                                            setIsOpen(false)
                                        }}
                                    >{option}</li>
                                ))
                            }
                        </ul>
                    </div>
                )
            }

        </div>
    );
};

export default SortDropdown;