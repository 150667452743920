export enum Classes {
    assassin = 'Assassin',
    fighter = 'Fighter',
    ranger = 'Ranger'
}

export enum ClassesMarket {
    assassin = 'Assassin',
    warrior = 'Warrior',
    mage = 'Mage'
}

export default {Classes, ClassesMarket}




